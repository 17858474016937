import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatStringToDate, serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import icon1 from "../../Resources/page1_ico1.png";
import icon2 from "../../Resources/page1_ico2.png";
import icon3 from "../../Resources/page1_ico3.png";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import FormSubmissionLoader from "../Shared/Loaders/FormSubmissionLoader/FormSubmissionLoader";
import { createAnimation } from "react-spinners/helpers/animation";

const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
};

const mapStyles = {
  flex: "1",
  // maxWidth: "100%",
  maxWidth: "55%",
  // marginBottom: "20px",
  marginBottom: "20px",
  marginRight: "35px",
  position: "relative",
  margin: "30px",
  paddingBottom: "56.25%",
  height: "0",
  order: "2", // Set order to 2 to appear below the form on mobile
};

const formContainerStyles = {
  flex: "1",
  // maxWidth: "100%",
  marginLeft: "25px",
  margin: "30px",
  paddingLeft: "10px",
  maxWidth: "38%",

  order: "1", // Set order to 1 to appear above the map on mobile
};

const formStyles = {
  marginBottom: "20px",
};

const inputStyles = {
  width: "100%",
  padding: "8px",
  border: "1px solid #ccc",
  borderRadius: "5px",
};

const buttonStyles = {
  display: "inline-block",
  padding: "8px 15px",
  backgroundColor: "#b2872f",
  color: "#fff",
  textDecoration: "none",
  borderRadius: "5px",
  transition: "background-color 0.3s ease",
};

const Contact = (props) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentProgram, setCurrentProgram] = useState(null);
  const [OAP, setOAP] = useState([]);

  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? arrayShuffle(props?.HomePageData.oap)
      : []
  );

  const [programs] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const [news] = useState(
    typeof props.HomePageData.news !== "undefined"
      ? props.HomePageData.news
      : []
  );

  const [newsImage] = useState(
    typeof props.HomePageData.newsImage !== "undefined"
      ? props.HomePageData.newsImage
      : []
  );

  const [newsCategory] = useState(
    typeof props.HomePageData.newsCategory !== "undefined"
      ? props.HomePageData.newsCategory
      : []
  );

  const [createItem, setCreateItem] = useState({
    ContactId: "",
    Subject: "",
    Name: "",
    EmailAddress: "",
    Message: "",
    StationId: "",
    PhoneNumber: "",
    Date: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      ContactId: "",
      Subject: "",
      Name: "",
      EmailAddress: "",
      Message: "",
      StationId: "",
      PhoneNumber: "",
      Date: "",
    });
  };

  const filteredOAPs = oap.filter((x) => x.StationId === Number(2));
  const filteredPrograms = programs
    .filter((x) => x.StationId === Number(2))
    .slice(0, 6);

  useEffect(() => {
    if (Object.keys(filteredPrograms).length > 0) {
      const checkProgramMatch = () => {
        const currentTime = new Date();

        const matchingProgram = programs.find((program) => {
          const startTime = new Date();
          const [startHour, startMinute] = program.StartTime.split(":");
          startTime.setHours(startHour, startMinute, 0, 0);

          const endTime = new Date();
          const [endHour, endMinute] = program.EndTime.split(":");
          endTime.setHours(endHour, endMinute, 0, 0);

          return currentTime >= startTime && currentTime <= endTime;
        });

        if (matchingProgram) {
          setCurrentProgram(matchingProgram);
        }
      };

      checkProgramMatch(); // Initial check on component mount

      const interval = setInterval(checkProgramMatch, 60000); // Check every minute

      return () => {
        clearInterval(interval);
      };
    }
    if (Object.keys(filteredPrograms).length < 1) {
      const matchingProgram = filteredPrograms.find(
        (r) => r.ProgramId === Number(1)
      );

      setCurrentProgram(matchingProgram);
    }
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      setOAP(filteredOAPs);
    }, 1000);
  }, [filteredOAPs, filteredPrograms, OAP]);

  if (Object.keys(OAP).length === 0) {
    return <HomeContentLoader />;
  }
  if (isSubmitting) {
    return <FormSubmissionLoader />;
  }

  const onEdit = (e) => {
    setCreateItem({
      ...createItem,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmittingForm = () => {
    setIsSubmitting(true);

    if (createItem.EmailAddress === "") {
      setTimeout(async () => {
        setIsSubmitting(false);
        await window.alert(`Please enter email address.`);
      }, 1000);
      return false;
    }

    if (createItem.Subject === "") {
      setTimeout(async () => {
        setIsSubmitting(false);
        await window.alert(`Please enter subject to your request.`);
      }, 1000);
      return false;
    }

    if (createItem.PhoneNumber === "") {
      setTimeout(async () => {
        setIsSubmitting(false);
        await window.alert(`Please enter subject to your request.`);
      }, 1000);
      return false;
    }

    if (Object.keys(createItem).length > 0) {
      const data = {
        ContactId: createItem.ContactId,
        Subject: createItem.Subject,
        Name: createItem.Name,
        EmailAddress: createItem.EmailAddress,
        Message: createItem.Message,
        StationId: 2,
        PhoneNumber: createItem.PhoneNumber,
        Date: `${currentMonth}/${currentDay}/${currentYear}`,
      };

      console.log("dada", data);

      axios
        .post(`${serverLink}shared/contact`, data)
        .then((res) => {
          if (res.data.message === "success") {
            setTimeout(async () => {
              setIsSubmitting(false);
              window.alert(
                `Request submitted successfully, our specialist will contact you soon!`
              );
              resetItem();
            }, 2000);
            return false;
          } else {
            setTimeout(async () => {
              setIsSubmitting(false);
              window.alert(
                `Sorry, form not submitted, check your internet connection and try!`
              );
            }, 2000);
            return false;
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  return (
    <ErrorBoundary>
      <div id="content">
        <div className="p146">
          <div style={containerStyles}>
            <div style={formContainerStyles}>
              <div style={formStyles}>
                <h3>Contact Info</h3>
                <address>
                  <p>No 7. Oron street GRA Aba, Abia state Nigeria.</p>
                  <p>
                    <span>Freephone: </span>(+234) 8109629153
                  </p>
                  <p>
                    <span>Telephone: </span>(+234) 8109629153
                  </p>

                  <p>
                    <span>E-mail: </span>
                    <a href="mailto:info@enyinba.com">info@enyinba.com</a>
                  </p>
                </address>
              </div>
              <div style={formStyles}>
                <div style={{ marginBottom: "20px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <label style={{ display: "block" }}>
                      Subject
                      <input
                        type="text"
                        name="Subject"
                        id="Subject"
                        required={true}
                        value={createItem.Subject}
                        onChange={onEdit}
                        placeholder="Subject"
                        style={inputStyles}
                      />
                      <span
                        style={{ display: "none" }}
                        className="empty-message"
                      >
                        *This field is required.
                      </span>
                      <span
                        style={{ display: "none" }}
                        className="error-message"
                      >
                        *This is not a valid name.
                      </span>
                    </label>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <label style={{ display: "block" }}>
                      Name
                      <input
                        type="text"
                        name="Name"
                        id="Name"
                        placeholder="Name"
                        required={true}
                        value={createItem.Name}
                        onChange={onEdit}
                        style={inputStyles}
                      />
                      <span
                        style={{ display: "none" }}
                        className="empty-message"
                      >
                        *This field is required.
                      </span>
                      <span
                        style={{ display: "none" }}
                        className="error-message"
                      >
                        *This is not a valid name.
                      </span>
                    </label>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <label style={{ display: "block" }}>
                      Email Address
                      <input
                        type="text"
                        name="EmailAddress"
                        id="EmailAddress"
                        required={true}
                        value={createItem.EmailAddress}
                        onChange={onEdit}
                        placeholder="Email Address"
                        style={inputStyles}
                      />
                      <span
                        style={{ display: "none" }}
                        className="empty-message"
                      >
                        *This field is required.
                      </span>
                      <span
                        style={{ display: "none" }}
                        className="error-message"
                      >
                        *This is not a valid email.
                      </span>
                    </label>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <label style={{ display: "block" }}>
                      Phone Number
                      <input
                        type="text"
                        name="PhoneNumber"
                        id="PhoneNumber"
                        required={true}
                        value={createItem.PhoneNumber}
                        onChange={onEdit}
                        placeholder="Phone Number"
                        style={inputStyles}
                      />
                      <span
                        style={{ display: "none" }}
                        className="empty-message"
                      >
                        *This field is required.
                      </span>
                      <span
                        style={{ display: "none" }}
                        className="error-message"
                      >
                        *This is not a valid email.
                      </span>
                    </label>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <label style={{ display: "block" }}>
                      Message
                      <textarea
                        name="Message"
                        id="Message"
                        placeholder="Message"
                        required={true}
                        value={createItem.Message}
                        onChange={onEdit}
                        style={{
                          ...inputStyles,
                          resize: "vertical",
                          height: "225px",
                        }}
                      ></textarea>
                      <span
                        style={{ display: "none" }}
                        className="empty-message"
                      >
                        *This field is required.
                      </span>
                      <span
                        style={{ display: "none" }}
                        className="error-message"
                      >
                        *The message is too short.
                      </span>
                    </label>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#D91414FF",
                        color: "white",
                        width: "100%",
                        height: "40px",
                        fontSize: "18px",
                        border: "none",
                      }}
                      className="btn btn-primary"
                      onClick={handleSubmittingForm}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={mapStyles}>
              <iframe
                className="map_c"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=No 7 Iron street GRA Aba, Abia state Nigeria&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                // src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d24214.807650104907!2d-73.94846048422478!3d40.65521573400813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1395650655094"
                style={{
                  border: "0",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
