import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";

// Define the animation
const marqueeAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`;

// Container for the marquee slider
const MarqueeContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: #4d5962; /* Set your desired background color */
`;

// Content to slide inside the marquee container
const MarqueeContent = styled.div`
  display: flex;
  animation: ${marqueeAnimation} 35s linear infinite;
`;

// Individual item inside the marquee slider
const MarqueeItem = styled.div`
  flex: 0 0 auto;
  padding: 10px 20px;
  font-size: 15px;
  color: #ffffff;
  /* Add any other styling you want for the slider items */
`;

const QuickInfo = (props) => {
  const [currentDateInfo, setCurrentDateInfo] = useState(null);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const firstItem = pathSegments[0];
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [oap] = useState(
    typeof props.HomePageData.oap !== "undefined" ? props.HomePageData.oap : []
  );

  function formatDateToDDMMYYYY(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const [votes] = useState(
    typeof props.HomePageData.votes !== "undefined"
      ? props.HomePageData.votes
      : []
  );

  const [stations] = useState(
    typeof props.HomePageData.stations !== "undefined"
      ? props.HomePageData.stations
      : []
  );

  const [programs] = useState(
    typeof props.HomePageData.programs !== "undefined"
      ? props.HomePageData.programs
      : []
  );

  const [headerNotification] = useState(
    typeof props.HomePageData.headerNotification !== "undefined"
      ? props.HomePageData.headerNotification
      : []
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const filteredStations = stations.filter((r) => r.StationId === Number(2));

  const filteredPrograms = programs.filter((r) => r.StationId === Number(2));

  const filteredHeaderNotification = headerNotification.filter(
    (r) => r.StationId === Number(2)
  );

  return (
    <>
      {filteredHeaderNotification &&
        Object.keys(filteredHeaderNotification).length > 0 && (
          <>
            {formatDateToDDMMYYYY(currentDate) >=
              filteredHeaderNotification[0].StartDate &&
              formatDateToDDMMYYYY(currentDate) >=
                filteredHeaderNotification[0].StartDate && (
                <div>
                  <MarqueeContainer>
                    <MarqueeContent>
                      <MarqueeItem>
                        {filteredHeaderNotification[0].Text1}{" "}
                        <a
                          href={filteredHeaderNotification[0].Link1}
                          target="_blank"
                          style={{
                            color: "rgba(245,177,32,0.76)",
                          }}
                        >
                          {filteredHeaderNotification[0].LinkText1}
                        </a>{" "}
                        {"|"} {filteredHeaderNotification[0].Text2}
                        <a
                          href={filteredHeaderNotification[0].Link2}
                          target="_blank"
                          style={{
                            color: "rgba(245,177,32,0.76)",
                          }}
                        >
                          {" "}
                          {filteredHeaderNotification[0].LinkText2}
                        </a>
                      </MarqueeItem>
                    </MarqueeContent>
                  </MarqueeContainer>
                </div>
              )}
          </>
        )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickInfo);
