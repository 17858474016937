import Routing from "./Components/Routers/Routing";
import { setHomePageContents, setLoginDetails } from "./Actions/Actions";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { serverLink } from "./Resources/Url";
import { BrowserRouter as Router } from "react-router-dom";
import HomeContentLoader from "./Components/Shared/Loaders/HomeContentLoader/HomeContentLoader";

function App(props) {
  const [isLoading, setIsLoading] = useState(true);

  const getHomeReport = async () => {
    await axios
      .get(`${serverLink}shared/contents`)
      .then((result) => {
        // if (Object.keys(result.data).length > 0) {
        props.setOnHomePageContents(result.data);

        setTimeout(async () => {
          setIsLoading(false);
        }, 2000);

        // }
      })
      .catch((e) => {
        console.log("Error Message Found", e);
        window.location.href = "https://incident.enyinba.com/downtime.html";
      });
  };

  useEffect(() => {
    getHomeReport().then((r) => {});
  }, []);

  console.log("Data");

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <Router>
          <Routing />
        </Router>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    // loginData: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageContents: (p) => {
      dispatch(setHomePageContents(p));
    },
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
