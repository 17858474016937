import "swiper/swiper.min.css";
import React, { useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { Link } from "react-router-dom";

const PodcastList = (props) => {
  const [podcast] = useState(
    typeof props.HomePageData.podcast !== "undefined"
      ? props.HomePageData.podcast
      : []
  );

  const filteredPodcast = podcast.filter(
    (x) => x.StationId === Number(2) || x.StationId === Number(0)
  );

  return (
    <ErrorBoundary>
      {/*TOPS NEWS END*/}

      <div
        className="bg1 p93"
        style={{
          backgroundColor: "rgba(196,190,190,0.71)",
        }}
      >
        <div className="container_12">
          <div className="grid_12">
            <div className="info_block">
              <h3
                className="col2"
                style={{
                  color: "#ffffff",
                }}
              >
                <p
                  style={{
                    color: "#ffffff",
                  }}
                >
                  Captivating moments on Legend FM <br />
                  <span className="col1">radio station!</span>
                </p>
              </h3>
              <h4
                style={{
                  color: "#ffffff",
                }}
              >
                The station that brings you intriguing programs
              </h4>
              <a href="https://live.enyinba.com" target="_blank">
                <i className="fa fa-play-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/*PROGRAM START*/}

      <div id="content">
        {/*PODCAST START*/}
        {filteredPodcast && Object.keys(filteredPodcast).length > 0 && (
          <>
            <div className="container_12 p100">
              <h3>Podcasts</h3>
              {filteredPodcast.map((item, index) => {
                return (
                  <>
                    <Link to={`/podcast/${item.Slug}`}>
                      <div className="grid_4 wrap">
                        <div className="thumb">
                          <img
                            src={item.Image}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}

                          {/*</div>*/}
                          <div
                            style={{
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              fontSize: "20px",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.Title}
                          </div>
                          <div
                            style={{
                              color: "black",
                              textAlign: "justify", // Center-align text
                            }}
                          >
                            {item.ShortDescription.split(" ")
                              .slice(0, 8)
                              .join(" ")}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
            </div>
          </>
        )}
        {/*PODCAST ENDS*/}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastList);
