import "swiper/swiper.min.css";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const Slider = (props) => {
  const [slider] = useState(
    typeof props.HomePageData.slider !== "undefined"
      ? props.HomePageData.slider
      : []
  );

  const filteredSlider = slider.filter((x) => x.StationId === Number(3));

  return (
    <ErrorBoundary>
      <div
        className="banner"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <div className="container_12">
          <div className="grid_12">
            <div className="banner_capt">
              <div className="title1" style={{ color: "white" }}>
                {filteredSlider[0].TopDescriptionOne} <br />
                {filteredSlider[0].TopDescriptionTwo}
              </div>
              <div className="title2">
                {filteredSlider[0].ButtomDescriptionOne} <br />
                {filteredSlider[0].ButtomDescriptionTwo}
              </div>
              <a
                href="https://live.enyinba.com"
                target="_blank"
                className="link"
                style={{ backgroundColor: "#31353d" }}
              >
                <i className="fa fa-play-circle"></i>Live Programs
              </a>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
